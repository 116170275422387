import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { ThemeProvider, styled } from '@mui/material/styles';

import Button from '../button';

import themer from '../../styles/material-theme';
import styles from './styles';

import ErrorImg from './images/500-error@2x.png';

const ErrorContent = styled('div')(styles.root);
const Description = styled('div')(styles.description);

const ErrorBoundary = ({ resetError }) => {
    const navigate = useNavigate();

    const handleBackToHomeClick = () => {
        navigate('/');
        resetError();
    };

    return (
        <ThemeProvider theme={themer}>
            <main>
                <ErrorContent className="container">
                    <Typography variant="h1" sx={styles.title}>
                        Oops!
                    </Typography>

                    <Description>Something went wrong on our end.</Description>

                    <Description>Please try again.</Description>

                    <Button onClick={handleBackToHomeClick}>Back to Home</Button>

                    <div style={styles.iconContainer}>
                        <img src={ErrorImg} alt="" style={styles.icon} />
                    </div>
                </ErrorContent>
            </main>
        </ThemeProvider>
    );
};

ErrorBoundary.propTypes = {
    resetError: PropTypes.func.isRequired,
};

export default ErrorBoundary;
