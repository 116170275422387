import Tokens from '../../styles/tokens';

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        alignItems: 'center',
        margin: '0 auto',
    },
    title: {
        textAlign: 'center',
        fontSize: '5rem',
        fontWeight: 'bold',
        lineHeight: '5.8rem',
        letterSpacing: '0.00304rem',
        color: Tokens.colors.logoBlue,
        paddingTop: 54,
        paddingBottom: 22,
    },
    description: {
        textAlign: 'center',
        fontSize: '1.6rem',
        fontWeight: '400',
        lineHeight: '2.9rem',
        color: Tokens.colors.gray,
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        height: 190,
        width: 313,
    },
};

export default styles;
