import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSnackbar } from '../components/snackbar/context';

export default function useRedirect(predicate, to, snackbarConfig) {
    const { openSnackbar } = useSnackbar();
    const navigate = useNavigate();
    // Custom memoization confuses the linter
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const snackbar = useMemo(() => snackbarConfig, [snackbarConfig?.message, snackbarConfig?.severity]);

    useEffect(() => {
        if (predicate) {
            navigate(to, { replace: true });

            if (snackbar) {
                openSnackbar(snackbar);
            }
        }
    }, [navigate, to, predicate, snackbar, openSnackbar]);
}
