import PropTypes from 'prop-types';
import Container from '@mui/material/Container';

import Block from './block';

const CenteredBlock = ({ maxWidth, color, sx, children }) => (
    <Container maxWidth={maxWidth}>
        <Block color={color} sx={{ px: 10, ...sx }}>
            {children}
        </Block>
    </Container>
);

CenteredBlock.propTypes = {
    children: PropTypes.node,
    maxWidth: PropTypes.string,
    color: PropTypes.string,
    sx: PropTypes.object,
};

CenteredBlock.defaultProps = {
    children: null,
    maxWidth: 'md',
    color: 'primary.dark',
    sx: {},
};

export default CenteredBlock;
