import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

import CenteredAlert from '../../components/centered-alert';
import CenteredBlock from '../../components/content/centered-block';
import WysiwygTypography from '../../components/wysiwyg-typography';

import API from '../../api';
import useAsync from '../../utils/use-async';

export const LEGAL_POLICY_TYPES = {
    TERMS: 'terms',
    PRIVACY: 'privacy',
    LAWYER_ADVERTISING: 'lawyer-advertising',
};

const pageConfigs = {
    [LEGAL_POLICY_TYPES.TERMS]: {
        title: 'Terms & Conditions',
        dataKey: 'terms_and_conditions',
    },
    [LEGAL_POLICY_TYPES.PRIVACY]: {
        title: 'Privacy Policy',
        dataKey: 'privacy_policy',
    },
    [LEGAL_POLICY_TYPES.LAWYER_ADVERTISING]: {
        title: 'Lawyer Advertising',
        dataKey: 'lawyer_advertising',
    },
};

const LegalPolicy = ({ type }) => {
    const { value, error, loading } = useAsync(() => API.fetchTermsAndConditions(), []);

    if (loading) {
        return <div />;
    }

    if (error) {
        return <CenteredAlert severity="error">Sorry, something went wrong</CenteredAlert>;
    }

    return (
        <>
            <Typography variant="h1" color="common.white" sx={{ mb: 4, fontWeight: 'extraBold' }}>
                {pageConfigs[type].title}
            </Typography>
            <CenteredBlock color="primary.dark">
                <WysiwygTypography>{value[pageConfigs[type]?.dataKey] || ''}</WysiwygTypography>
            </CenteredBlock>
        </>
    );
};

LegalPolicy.propTypes = {
    type: PropTypes.oneOf(Object.values(LEGAL_POLICY_TYPES)).isRequired,
};

export default LegalPolicy;
