import MuiMenu, { menuClasses } from '@mui/material/Menu';
import { styled } from '@mui/material/styles';

const Menu = styled((props) => (
    <MuiMenu
        {...props}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
    />
))(({ theme }) => ({
    [`& .${menuClasses.paper}`]: {
        minWidth: theme.spacing(17), // 136px
    },
}));

export default Menu;
