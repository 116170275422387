import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import AccountMenu from './account-menu';
import CarrierTabs from './carrier-tabs';
import { getCurrentCarrier } from '../../redux/auth';
import HinshawLogo from '../../images/hinshaw.png';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

const Logo = styled('img')(({ theme }) => ({
    width: '150px',
    maxHeight: theme.mixins.toolbar.height,
}));

const LoggedInHeader = () => {
    const currentCarrier = useSelector(getCurrentCarrier);
    const location = useLocation();

    const isCarriersPage = location.pathname === '/carriers';
    const noCarrierSelected = !currentCarrier || isCarriersPage;

    return (
        <header>
            <AppBar
                component="nav"
                position="fixed"
                sx={{ backgroundColor: (theme) => theme.palette.background.paper, pl: 4 }}
            >
                <Toolbar>
                    <Box sx={{ display: 'flex', flexGrow: 1 }}>
                        <Stack
                            alignItems="center"
                            direction="row"
                            divider={
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{ height: (theme) => theme.spacing(4), alignSelf: 'initial' }}
                                />
                            }
                            spacing={2}
                        >
                            {!noCarrierSelected ? (
                                <Logo src={currentCarrier.logo} alt="Carrier Logo" />
                            ) : (
                                <Logo src={HinshawLogo} alt="Hinshaw Logo" />
                            )}
                            {!noCarrierSelected && (
                                <>
                                    <Logo src={HinshawLogo} alt="Hinshaw Logo" />
                                    <Typography variant="h3" color="primary" sx={{ fontWeight: 'medium' }}>
                                        Lawyering Law
                                    </Typography>
                                </>
                            )}
                        </Stack>
                    </Box>
                    {!noCarrierSelected && <CarrierTabs />}
                    <Box
                        {...(noCarrierSelected && {
                            sx: { display: 'flex', flexGrow: 1, justifyContent: 'flex-end' },
                        })}
                    >
                        <AccountMenu />
                    </Box>
                </Toolbar>
            </AppBar>
            <Offset />
        </header>
    );
};

export default LoggedInHeader;
