import React from 'react';
import { Link } from 'react-router-dom';
import MuiButton from '@mui/material/Button';
import MuiIconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import { styled, darken } from '@mui/material/styles';

import noop from '../../utils/noop';
import Tokens from '../../styles/tokens';

// Aliases for MUI button variants to bring this component in-line with our usual button variant terminology
// as well as support special functionality by variant e.g. different rendering for the icon variant
const appToMuiVariants = {
    primary: 'contained',
    secondary: 'outlined',
    tertiary: 'outlined',
    icon: 'icon',
    light: 'contained',
    'text-light': 'text',
};

// Note we're kinda breaking Mui's Button's color prop here, as we're overriding color by variant
// This can lead to some unexpected results / make the button less customizable, but the intent / hope
// was to simplify the component's API, to reuse common sets of styles by the variant prop, and that
// if requirements push us outside those, move should be revising styles here first (or leveraging
// an existing variant, ideally). Generally, thought process was that instead of using all of the tools
// MUI puts at our disposal, try to wrangle them into a more predictable, consistent set of options
// , building our own, dumber, smaller design language tailored to the specific requirements of the project.
// Less options means easier to keep design implementation consistent? YMMV / sorry if clunky in practice

const StyledButton = styled(MuiButton, {
    shouldForwardProp: (prop) => prop !== '_origVariant',
})(({ theme, _origVariant }) => ({
    '&.MuiButton-root': {
        '&:focus': {
            boxShadow: '0px 0px 5px rgba(0, 128, 255, 0.9)',
        },
    },
    '&.MuiButton-containedPrimary': {
        backgroundColor: _origVariant === 'light' ? theme.palette.primary.light : theme.palette.primary.dark,
        padding:
            _origVariant === 'light'
                ? `${theme.spacing(1)} ${theme.spacing(1.75)}`
                : `${theme.spacing(1.5)} ${theme.spacing(1.75)}`,
        '&:hover': {
            backgroundColor:
                _origVariant === 'light' ? darken(theme.palette.primary.light, 0.25) : theme.palette.primary.main,
        },
        '&.Mui-disabled, &[aria-disabled=true]': {
            backgroundColor: Tokens.colors.neutralLightestGray,
            color: Tokens.colors.neutralLightGray,
            cursor: 'not-allowed',
        },
    },
    '&.MuiButton-outlinedPrimary': {
        ...(_origVariant === 'secondary' && {
            padding: `${theme.spacing(2.5)} ${theme.spacing(3)}`,
            color: theme.palette.common.black,
            border: `2px solid ${theme.palette.success.dark}`,
            borderRadius: theme.shape.borderRadius * 2,
            '&:hover': {
                backgroundColor: Tokens.colors.lightGray,
                border: `2px solid ${theme.palette.success.dark}`,
                borderRadius: theme.shape.borderRadius * 2,
            },
        }),
        ...(_origVariant === 'tertiary' && {
            padding: `${theme.spacing(1.5)} ${theme.spacing(1.75)}`,
            border: `1px solid ${Tokens.colors.mediumBlue}`,
        }),
        '&.Mui-disabled, &[aria-disabled=true]': {
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${Tokens.colors.neutralLightestGray}`,
            color: Tokens.colors.neutralLightGray,
            cursor: 'not-allowed',
        },
    },
    '&.MuiButton-textPrimary': {
        color: _origVariant === 'text-light' ? theme.palette.common.white : theme.palette.primary.dark,
        backgroundColor: 'transparent',
        borderRadius: 0, // so hover border bottom doesn't appear rounded
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent',
            // Simulates hover effect of underline
            // border bottom is not suitable for this, as borders take up space, nudging adjacent elements
            boxShadow: `0 1px 0 0 ${_origVariant === 'text-light' ? theme.palette.common.white : theme.palette.primary.dark}`,
        },
        '&.Mui-disabled, &[aria-disabled=true]': {
            pointerEvents: 'none',
            color: Tokens.colors.neutralLightGray,
            cursor: 'not-allowed',
        },
    },
}));

const IconButton = styled(MuiIconButton)(({ theme }) => ({
    '&.MuiIconButton-root': {
        color: theme.palette.common.white,
        width: theme.spacing(5),
        height: theme.spacing(5),
        borderRadius: theme.shape.borderRadius,
        '&:focus': {
            boxShadow: '0px 0px 5px rgba(0, 128, 255, 0.9)',
        },
        backgroundColor: theme.palette.primary.dark,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        '&.Mui-disabled, &[aria-disabled=true]': {
            backgroundColor: Tokens.colors.neutralLightestGray,
            color: Tokens.colors.neutralLightGray,
            cursor: 'not-allowed',
        },
    },
}));

const Button = React.forwardRef(
    ({ children, onClick, disabled, variant, startIcon, endIcon, to, component, ariaLabel, ...props }, ref) => {
        if (variant === 'icon') {
            return (
                <IconButton
                    {...props}
                    aria-label={ariaLabel}
                    disabled={disabled}
                    disableFocusRipple
                    disableRipple
                    onClick={onClick}
                    ref={ref}
                >
                    {children}
                </IconButton>
            );
        }

        return (
            <StyledButton
                {...props}
                disabled={disabled}
                disableElevation
                disableFocusRipple
                disableRipple
                onClick={onClick}
                _origVariant={variant}
                variant={appToMuiVariants[variant] ?? variant}
                startIcon={startIcon}
                endIcon={endIcon}
                // Required for configuring a button as a router-aware link
                {...(to && { to, component: Link })}
                ref={ref}
            >
                {children}
            </StyledButton>
        );
    },
);

Button.propTypes = {
    disabled: PropTypes.bool,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    variant: PropTypes.string,
    startIcon: PropTypes.node,
    endIcon: PropTypes.node,
    to: PropTypes.string,
    component: PropTypes.elementType,
    ariaLabel: PropTypes.string,
};

Button.defaultProps = {
    disabled: false,
    onClick: noop,
    variant: 'primary',
    startIcon: null,
    endIcon: null,
    to: null,
    component: null,
    ariaLabel: 'Icon button',
};

export default Button;
