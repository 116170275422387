const ROLES = {
    TNP_ADMIN: 'administrator', // WP's admin role; super admin privs reserved for T&P
    HINSHAW_ADMIN: 'hinshaw_admin',
    CARRIER: 'carrier',
    LAWYER: 'lawyer',
};

const RESOURCE_TYPES = {
    PDF: 'pdf',
    CLE: 'cle',
    ARTICLE: 'article',
    LINK: 'link',
};

// bit of a misnomer; may apply to Articles and PDFs, not just Articles
const ARTICLE_TYPES = {
    GENERAL_CONTENT: 'general',
    TIP_OF_THE_MONTH: 'tip',
    NEWSLETTER: 'newsletter',
};

const EVENT_TYPES = {
    RESOURCE_VIEW: 'resource_view',
    RISK_CONSULTATION_REQUEST: 'risk_consultation_request',
    SITE_VISIT: 'site_visit', // any entry to the auth-protected portion of the site (setup 2FA, verify 2FA, return to site while auth token still valid)
};

// eslint-disable-next-line import/prefer-default-export
export { ARTICLE_TYPES, RESOURCE_TYPES, ROLES, EVENT_TYPES };
