import { ROLES } from './constants';

const isAdmin = (user) => [ROLES.TNP_ADMIN, ROLES.HINSHAW_ADMIN].includes(user?.role);

const getUserCarrierId = (user, currentCarrier) => {
    if (!user) {
        return null;
    }

    if (user.role === ROLES.CARRIER) {
        return user.carrier.id;
    }

    if (user.role === ROLES.LAWYER) {
        return user.policy.carrier.id;
    }

    if (isAdmin(user)) {
        return currentCarrier?.id;
    }

    return null;
};

export { isAdmin, getUserCarrierId };
