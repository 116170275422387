// General purpose utility functions

const withQuery = (path, params) => {
    const searchParams = new URLSearchParams(params);
    return `${path}?${searchParams.toString()}`;
};

// List nabbed from https://github.com/hapijs/bounce
const systemErrors = [EvalError, RangeError, ReferenceError, SyntaxError, TypeError, URIError];

// eslint-disable-next-line padded-blocks, arrow-body-style
const isSystemError = (err) => {
    return systemErrors.some(
        (system) =>
            err instanceof system || // sync errors
            err.name === system.name,
    ); // thunk errors (serialized)
};

export { withQuery, isSystemError, systemErrors };
