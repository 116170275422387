export default {
    fontWeights: {
        regular: 400,
        medium: 500,
        semiBold: 600,
        bold: 700,
        extraBold: 800,
    },
    colors: {
        gray: '#666',
        logoBlue: '#002856',
        lightBlue: '#27AAE1',
        mediumBlue: '#B0C7DA',
        darkBlue: '#004F8E',
        lightPurple: '#773377',
        darkPurple: '#401B61',
        secondaryBlue: '#DBEFF7',
        transparentBlue: 'rgba(39, 170, 225, 0.15)',
        lightGreen: '#ABD3CB',
        mediumGreen: '#24997D', // success, in designs
        darkGreen: '#16555C',
        mediumGray: '#A7A9AC', // Not for text
        neutralLightGray: '#CFDAE6',
        neutralLightestGray: '#F0F4F7',
        lightGray: '#E6E7E8',
        lightestGray: '#F1F1F1',
        red: '#AC3430',
        yellow: '#E1BB27',
        offwhiteBackground: '#FBFBFB',
    },
};
