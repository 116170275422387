// This rule is basically in direct conflict w/ Immer
/* eslint-disable no-param-reassign */
import Axios from 'axios';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import API from '../api';
import * as AuthUtils from '../utils/auth';
import { ROLES } from '../utils/constants';

// eslint-disable-next-line consistent-return
const withAxiosErrorHandling = (fn) => async (arg, thunkAPI) => {
    try {
        return await fn(arg, thunkAPI);
    } catch (error) {
        if (!Axios.isAxiosError(error)) {
            throw error;
        }

        // match redux SerializedError interface, plus add data typically useful to us in error inspection
        // https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
        return thunkAPI.rejectWithValue({
            name: error.name,
            message: error.message,
            stack: error.stack,
            code: error.code,
            response: {
                status: error.response?.status,
                data: error.response?.data,
                statusText: error.response?.statusText,
            },
            // spoof as an axios error, since we expect to use it as such
            // literally the field checked by Axios' isAxiosError
            isAxiosError: true,
        });
    }
};

const fetchCurrentUser = createAsyncThunk(
    'auth/fetchCurrentUser',
    // eslint-disable-next-line no-unused-vars
    withAxiosErrorHandling(async ({ reauthenticating, reqOpts }, thunkAPI) => {
        const source = Axios.CancelToken.source();
        thunkAPI.signal.addEventListener('abort', () => {
            source.cancel();
        });

        const data = await API.fetchCurrentUser({ ...reqOpts, cancelToken: source.token });

        return data;
    }),
);

const updateCurrentUser = createAsyncThunk(
    'auth/updateCurrentUser',
    withAxiosErrorHandling(async ({ formValues, reqOpts }, thunkAPI) => {
        const source = Axios.CancelToken.source();
        thunkAPI.signal.addEventListener('abort', () => {
            source.cancel();
        });

        const data = await API.updateCurrentUser(formValues, { ...reqOpts, cancelToken: source.token });
        return data;
    }),
);

const fetchCarrier = createAsyncThunk(
    'auth/fetchCarrier',
    withAxiosErrorHandling(async ({ carrierId, reqOpts }, thunkAPI) => {
        const source = Axios.CancelToken.source();
        thunkAPI.signal.addEventListener('abort', () => {
            source.cancel();
        });
        const data = await API.fetchCarrier({ carrierId }, { ...reqOpts, cancelToken: source.token });

        return data;
    }),
);

const login = createAsyncThunk(
    'auth/login',
    withAxiosErrorHandling(async ({ formValues, reqOpts }, thunkAPI) => {
        const { proofToken, code } = formValues;
        const data = await API.verifyUser({ key: proofToken, code }, reqOpts);

        localStorage.setItem('authToken', data.token);

        const user = await thunkAPI.dispatch(fetchCurrentUser({ reauthenticating: false })).unwrap();

        if (!AuthUtils.isAdmin(user)) {
            await thunkAPI.dispatch(fetchCarrier({ carrierId: AuthUtils.getUserCarrierId(user) })).unwrap();
        }

        return user;
    }),
);

const logout = createAsyncThunk(
    'auth/logout',
    withAxiosErrorHandling(async () => {
        const token = localStorage.getItem('authToken');

        // cleanup out-of-band data only if it looks like the user had been logged in (has a token)
        // the API call here would 401 without an authToken, triggering the responses interceptor in routes/index.js,
        // which would then re-call logout, ad infinitum. Instead, we clean up the token, such that a 401
        // on first call here, while re-triggering this thunk from response interceptor, would skip the API call
        // itself on rerun
        if (token) {
            try {
                await API.logout();
            } finally {
                localStorage.removeItem('authToken');
                localStorage.removeItem('adminSelectedCarrier');
            }
        }
    }),
);

const selectAdminCarrier = createAsyncThunk(
    'auth/selectCarrier',
    withAxiosErrorHandling(async ({ carrierId, reqOpts }, thunkAPI) => {
        const source = Axios.CancelToken.source();
        thunkAPI.signal.addEventListener('abort', () => {
            source.cancel();
        });
        localStorage.setItem('adminSelectedCarrier', carrierId); // optimistic; assume that even if the fetch fails, the user will select a carrier again on the /carriers page
        await thunkAPI.dispatch(fetchCarrier({ carrierId, reqOpts })).unwrap();
    }),
);

const resetAdminCarrier = createAsyncThunk('auth/resetCarrier', () => {
    localStorage.removeItem('adminSelectedCarrier');
});

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        loading: false,
        currentUser: null,
        currentCarrier: null,
        inflightRequests: {},
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            // login
            .addCase(login.pending, (state, action) => {
                state.loading = true;
                state.inflightRequests.login = action.meta.requestId;
            })
            .addCase(login.fulfilled, (state, action) => {
                if (state.loading && state.inflightRequests.login === action.meta.requestId) {
                    state.loading = false;
                    state.authToken = action.payload;
                    state.inflightRequests.login = null;
                }
            })
            .addCase(login.rejected, (state, action) => {
                if (state.loading && state.inflightRequests.login === action.meta.requestId) {
                    state.loading = false;
                    state.inflightRequests.login = null;
                }
            })
            // Fetch current user
            .addCase(fetchCurrentUser.pending, (state, action) => {
                if (action.meta.arg.reauthenticating) {
                    state.loading = true;
                }

                state.inflightRequests.fetchCurrentUser = action.meta.requestId;
            })
            .addCase(fetchCurrentUser.fulfilled, (state, action) => {
                if (state.inflightRequests.fetchCurrentUser === action.meta.requestId) {
                    state.currentUser = action.payload;
                    state.inflightRequests.fetchCurrentUser = null;

                    if (action.meta.arg.reauthenticating) {
                        state.loading = false;
                    }
                }
            })
            .addCase(fetchCurrentUser.rejected, (state, action) => {
                if (state.inflightRequests.fetchCurrentUser === action.meta.requestId) {
                    state.inflightRequests.fetchCurrentUser = null;

                    if (action.meta.arg.reauthenticating) {
                        state.loading = false;
                    }
                }
            })
            // Fetch carrier
            .addCase(fetchCarrier.pending, (state, action) => {
                // TODO Ideally derive key from action somehow
                state.inflightRequests.fetchCarrier = action.meta.requestId;
            })
            .addCase(fetchCarrier.fulfilled, (state, action) => {
                if (state.inflightRequests.fetchCarrier === action.meta.requestId) {
                    state.currentCarrier = action.payload;
                    state.inflightRequests.fetchCarrier = null;
                }
            })
            .addCase(fetchCarrier.rejected, (state, action) => {
                if (state.inflightRequests.fetchCarrier === action.meta.requestId) {
                    state.inflightRequests.fetchCarrier = null;
                }
            })
            // Reset carrier
            .addCase(resetAdminCarrier.fulfilled, (state) => {
                state.currentCarrier = null;
            })
            // Logout
            .addCase(logout.fulfilled, () => authSlice.getInitialState())
            .addCase(logout.rejected, () => authSlice.getInitialState()) // reset no matter what, as we throw away the user's token no matter what
            // Update current user
            .addCase(updateCurrentUser.pending, (state, action) => {
                state.inflightRequests.fetchCurrentUser = action.meta.requestId;
            })
            .addCase(updateCurrentUser.fulfilled, (state, action) => {
                if (state.inflightRequests.fetchCurrentUser === action.meta.requestId) {
                    state.currentUser = action.payload;
                    state.inflightRequests.fetchCurrentUser = null;
                }
            })
            .addCase(updateCurrentUser.rejected, (state, action) => {
                if (state.inflightRequests.fetchCurrentUser === action.meta.requestId) {
                    state.inflightRequests.fetchCurrentUser = null;
                }
            });
    },
    selectors: {
        getIsAuthenticated: createSelector(
            (authState) => authState,
            (authState) => !!authState.currentUser,
        ),
        getCurrentUser: createSelector(
            (authState) => authState,
            (authState) => authState.currentUser,
        ),
        getIsAuthenticating: createSelector(
            (authState) => authState,
            (authState) => authState.loading,
        ),
        getCurrentCarrier: createSelector(
            (authState) => authState,
            (authState) => authState.currentCarrier,
        ),
        getIsCarrierLoading: createSelector(
            (authState) => authState,
            (authState) => !!authState.inflightRequests.fetchCarrier,
        ),
        getUserCarrierId: createSelector(
            (authState) => authState.currentUser,
            (authState) => authState.currentCarrier,
            AuthUtils.getUserCarrierId,
        ),
        getIsAdmin: createSelector((authState) => authState.currentUser, AuthUtils.isAdmin),
        getIsHinshawAdmin: createSelector(
            (authState) => authState.currentUser,
            (user) => user.role === ROLES.HINSHAW_ADMIN,
        ),
    },
});

export { fetchCurrentUser, updateCurrentUser, login, logout, fetchCarrier, selectAdminCarrier, resetAdminCarrier };

export const {
    getIsAuthenticated,
    getCurrentUser,
    getIsAuthenticating,
    getCurrentCarrier,
    getIsCarrierLoading,
    getUserCarrierId,
    getIsAdmin,
    getIsHinshawAdmin,
} = authSlice.selectors;

// Export the reducer, either as a default or named export
export default authSlice;
