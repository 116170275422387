import Axios from 'axios';

const internals = {
    host: process.env.REACT_APP_API_HOST,
    getApiBase: () => {
        const { host } = internals;
        return `${host}/wp-json`;
    },
};

const client = Axios.create({
    baseURL: internals.getApiBase(),
    responseType: 'json',
    headers: { common: {} },
});
export default client;

client.batch = (reqs) => {
    const { prefix } = internals;

    const requests = reqs.map((request) => ({
        ...request,
        path: `${prefix}${request.path}`,
    }));

    return client.post(internals.getApiBase(), { requests });
};

// see routes/index for response interceptor for handling auth failure redirects
client.interceptors.request.use((config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

const isAuthErrorResponse = (error) => Axios.isAxiosError(error) && error.response?.status === 401;

export { isAuthErrorResponse };
