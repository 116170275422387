import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Link from '../link';

const FooterBar = styled('footer')(({ theme, isMobile }) => ({
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    height: isMobile ? `calc(${theme.mixins.footer.height} * 2)` : theme.mixins.footer.height,
}));

const Footer = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    if (isMobile) {
        return (
            <FooterBar isMobile={isMobile}>
                <Stack sx={{ width: '100%', alignItems: 'center' }} spacing={2}>
                    <Stack
                        spacing={2}
                        direction="row"
                        divider={
                            <Divider
                                orientation="vertical"
                                sx={{ backgroundColor: 'common.white', height: theme.spacing(3), alignSelf: 'initial' }}
                            />
                        }
                        sx={{ justifyContent: 'center' }}
                    >
                        <Link sx={{ color: 'common.white' }} variant="caption" to="/lawyer-advertising">
                            Lawyer Advertising
                        </Link>
                        <Link sx={{ color: 'common.white' }} variant="caption" to="/privacy-policy">
                            Privacy Policy
                        </Link>
                        <Link sx={{ color: 'common.white' }} variant="caption" to="/terms-and-conditions">
                            Terms and Conditions
                        </Link>
                    </Stack>
                    <Link href="mailto:info.lawyeringlaw@hinshawlaw.com" target="_blank" sx={{ color: 'common.white' }}>
                        <MailOutlinedIcon fontSize="caption" /> info.lawyeringlaw@hinshawlaw.com
                    </Link>
                    <Typography variant="h3" color="common.white">
                        {new Date().getFullYear()} Hinshaw & Culbertson LLP
                    </Typography>
                    <Typography
                        color="common.white"
                        sx={{ height: '100%' /* align horizontally with copyright text */ }}
                    >
                        ATTORNEY ADVERTISING pursuant to New York DR 2-101(f)
                    </Typography>
                </Stack>
            </FooterBar>
        );
    }

    return (
        <FooterBar isMobile={isMobile}>
            <Toolbar>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={7} sx={{ display: 'flex', flexGrow: 1 }}>
                        <Stack spacing={2} direction="column">
                            <Stack
                                spacing={2}
                                direction="row"
                                divider={
                                    <Divider
                                        orientation="vertical"
                                        sx={{ backgroundColor: 'common.white', height: theme.spacing(3) }}
                                    />
                                }
                            >
                                <Typography variant="h3" color="common.white">
                                    {new Date().getFullYear()} Hinshaw & Culbertson LLP
                                </Typography>
                                <Typography
                                    color="common.white"
                                    sx={{ height: '100%' /* align horizontally with copyright text */ }}
                                >
                                    ATTORNEY ADVERTISING: Prior results do not guarantee a similar outcome.
                                </Typography>
                            </Stack>
                            <Link
                                href="mailto:info.lawyeringlaw@hinshawlaw.com"
                                target="_blank"
                                sx={{ color: 'common.white' }}
                            >
                                <MailOutlinedIcon fontSize="caption" /> info.lawyeringlaw@hinshawlaw.com
                            </Link>
                        </Stack>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={5}
                        sx={{
                            display: 'flex',
                            flexGrow: 1,
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Stack
                            spacing={2}
                            direction="row"
                            divider={
                                <Divider
                                    orientation="vertical"
                                    sx={{
                                        backgroundColor: 'common.white',
                                        height: theme.spacing(3),
                                        alignSelf: 'initial',
                                    }}
                                />
                            }
                        >
                            <Link sx={{ color: 'common.white' }} to="/lawyer-advertising">
                                Lawyer Advertising
                            </Link>
                            <Link sx={{ color: 'common.white' }} to="/privacy-policy">
                                Privacy Policy
                            </Link>
                            <Link sx={{ color: 'common.white' }} to="/terms-and-conditions">
                                Terms and Conditions
                            </Link>
                        </Stack>
                    </Grid>
                </Grid>
            </Toolbar>
        </FooterBar>
    );
};

export default Footer;
