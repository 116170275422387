import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';

import { getIsAuthenticated } from '../redux/auth';
import Header from '../components/header';
import Footer from '../components/footer';
import Tokens from '../styles/tokens';
import HinshawLogo from '../images/hinshaw.png';

import './print.css';

const Content = styled('div')(({ theme }) => ({
    // https://css-tricks.com/books/greatest-css-tricks/hard-stop-gradients/
    background: `linear-gradient(
        to bottom,
        ${theme.palette.primary.main},
        ${theme.palette.primary.main} 37.5%,
        ${Tokens.colors.lightestGray} 37.5%,
        ${Tokens.colors.lightestGray})
    `,
    height: `calc(100vh - ${theme.mixins.toolbar.height})`,
    overflowY: 'auto',
}));

const PrintHeader = () => (
    <Stack
        id="print-header"
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ py: 2, display: 'none' }}
    >
        <img src={HinshawLogo} alt="Hinshaw Logo" style={{ width: '150px' }} />
        <Typography variant="h3" color="primary" sx={{ fontWeight: 'medium' }}>
            Lawyering Law
        </Typography>
    </Stack>
);

const MainLayout = () => {
    const isAuthenticated = useSelector(getIsAuthenticated);
    const theme = useTheme();

    return (
        <>
            <PrintHeader />
            <Header />
            <Content isAuthenticated={isAuthenticated} id="ll-global-scroll-container">
                <Container
                    component="main"
                    maxWidth="xl"
                    sx={{
                        // padding to prevent margin collapse
                        pt: 10,
                        pb: 5,
                        // Our main content should fill all available space outside the header and footer (both of which are theme.mixins.toolbar.height tall),
                        // This means, at minimum, the space remaining in the viewport's height after those toolbars.
                        // If taller, though, the content should push the footer down per the content's intrinsic height (fit-content)
                        // footer calc must be kept in sync w/ same logic in components/footer
                        minHeight: {
                            xs: `calc(100vh - ${theme.mixins.toolbar.height} - calc(${theme.mixins.footer.height} * 2))`,
                            lg: `calc(100vh - ${theme.mixins.toolbar.height} - ${theme.mixins.footer.height})`,
                        },
                        height: 'fit-content',
                    }}
                >
                    {/*
                        Calling out an unexpected bug, to explain nesting of Suspense boundary here
                        If our Header is nested within Suspense, then, on navigating by selecting a menu item,
                        the menu detaches from the toolbar and jumps to the bottom of the screen because our menu component
                        was unmounted while still showing an open state and doesn't react to the new menu instance mounting (tho
                        does close on click)
                    */}
                    <Suspense fallback={<div />}>
                        <Outlet />
                    </Suspense>
                </Container>
                <Footer />
            </Content>
        </>
    );
};

export default MainLayout;
