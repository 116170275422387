import { useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MuiTab, { tabClasses } from '@mui/material/Tab';
import MuiTabs, { tabsClasses } from '@mui/material/Tabs';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import Menu from './menu';
import MenuItem from '../menu-item';
import Tokens from '../../styles/tokens';
import useRouteMatch from '../../utils/use-route-match';

const Tab = styled((props) => (
    <MuiTab {...props} disableRipple disableFocusRipple component={Link} sx={{ px: 4, py: 2.5 }} />
))(({ theme }) => ({
    color: theme.palette.primary.main,
    ':hover': {
        backgroundColor: Tokens.colors.transparentBlue,
    },
    [`&.${tabClasses.selected}`]: {
        fontWeight: theme.typography.fontWeightBold,
        backgroundColor: Tokens.colors.transparentBlue,
    },
}));

const Tabs = styled(MuiTabs)(({ theme }) => ({
    ...theme.mixins.toolbar,
    [`& .${tabsClasses.indicator}`]: {
        backgroundColor: theme.palette.primary.light,
        height: '3px',
    },
}));

const CarrierTabs = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Copied from https://mui.com/material-ui/integrations/routing/#tabs
    // You need to provide the routes in descendant order.
    // This means that if you have nested routes like:
    // users, users/new, users/edit.
    // Then the order should be ['users/add', 'users/edit', 'users'].
    const { currentRoute } = useRouteMatch([
        '/dashboard', // Dashboard
        '/tip-of-the-month',
        '/newsletter',
        '/about-us',
    ]);

    return (
        <>
            {/* Desktop menu */}
            <Tabs value={currentRoute} sx={{ display: { xs: 'none', lg: 'flex' } }}>
                <Tab label="Dashboard" value="/dashboard" to="/dashboard" />
                <Tab label="Tip of the Month" value="/tip-of-the-month" to="/tip-of-the-month" />
                <Tab label="Newsletter" value="/newsletter" to="/newsletter" />
                <Tab label="About Us" value="/about-us" to="/about-us" />
                <Tab
                    // Hack to quiet MUI complaining about an invalid tab value when user visits a route not represented by a tab
                    // , in which case the tab value is null
                    // MUI also complains about display: none (tab not in doc flow), hence the more convoluted CSS
                    value={null}
                    sx={{
                        visibility: 'hidden',
                        display: 'block',
                        width: 0,
                        minWidth: 0,
                        p: 0,
                    }}
                />
            </Tabs>
            {/* Mobile menu */}
            <Box sx={{ display: { lg: 'none' } }}>
                <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    disableRipple
                    disableFocusRipple
                >
                    <MenuIcon />
                </IconButton>
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose} onClick={handleClose}>
                    <MenuItem component={Link} to="/dashboard" selected={currentRoute === '/dashboard'}>
                        Dashboard
                    </MenuItem>
                    <MenuItem component={Link} to="/tip-of-the-month" selected={currentRoute === '/tip-of-the-month'}>
                        Tip of the Month
                    </MenuItem>
                    <MenuItem component={Link} to="/newsletter" selected={currentRoute === '/newsletter'}>
                        Newsletter
                    </MenuItem>
                    <MenuItem component={Link} to="/about-us" selected={currentRoute === '/about-us'}>
                        About Us
                    </MenuItem>
                </Menu>
            </Box>
        </>
    );
};

export default CarrierTabs;
