import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';

const CenteredAlert = ({ children, severity, sx, ...props }) => (
    <Alert
        severity={severity}
        variant="filled"
        // https://mui.com/system/getting-started/the-sx-prop/#passing-the-sx-prop
        sx={[
            { maxWidth: '60%', margin: '0 auto' }, // common styling for alerts displayed at top of pages, typically to announce loading errors loudly
            ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        {...props}
    >
        {children}
    </Alert>
);

CenteredAlert.propTypes = {
    children: PropTypes.node,
    severity: PropTypes.string.isRequired,
    sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.func]),
};

CenteredAlert.defaultProps = {
    children: '',
    sx: [],
};

export default CenteredAlert;
