import { combineSlices, configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import authSlice from './auth';
import thunkCrashReporter from './thunk-crash-reporter';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const store = configureStore({
    devTools: process.env.REACT_APP_DEPLOY_ENV !== 'production',
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunkCrashReporter),
    reducer: combineSlices(authSlice),
});

export default store;
