import { createContext, useCallback, useContext, useMemo, useReducer } from 'react';
import { produce } from 'immer';
import PropTypes from 'prop-types';

import Snackbar from '.';

const SnackbarContext = createContext();

const initialState = {
    message: '',
    severity: undefined,
    props: {},
};

const SnackbarProvider = ({ children }) => {
    const [snackbarConf, dispatch] = useReducer(
        /* eslint-disable no-param-reassign, consistent-return */
        produce((draft, action) => {
            switch (action.type) {
                case 'OPEN': {
                    const { message, severity, props } = action.payload;
                    draft.message = message;
                    draft.severity = severity;
                    draft.props = props;
                    break;
                }
                case 'CLOSE':
                    return initialState;
                default:
                    break;
            }
        }),
        /* eslint-enable no-param-reassign */
        initialState,
    );

    const closeSnackbar = useCallback(() => {
        dispatch({ type: 'CLOSE' });
    }, [dispatch]);

    const openSnackbar = useCallback(
        ({ message, severity, ...props }) => {
            dispatch({ type: 'OPEN', payload: { message, severity, props } });
        },
        [dispatch],
    );

    // Assume these might end up in a deps list somewhere, so stabilize
    const value = useMemo(
        () => ({
            openSnackbar,
            closeSnackbar,
        }),
        [closeSnackbar, openSnackbar],
    );

    const { message, severity, props } = snackbarConf;

    return (
        <SnackbarContext.Provider value={value}>
            {children}
            <Snackbar open={!!message} message={message} severity={severity} onClose={closeSnackbar} {...props} />
        </SnackbarContext.Provider>
    );
};

SnackbarProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

function useSnackbar() {
    const context = useContext(SnackbarContext);
    return context;
}

export { SnackbarContext, SnackbarProvider, useSnackbar };
