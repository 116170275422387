import React from 'react';
import { styled, alpha } from '@mui/material/styles';
import MuiMenuItem from '@mui/material/MenuItem';

import Tokens from '../../styles/tokens';

const MenuItem = styled(
    React.forwardRef((props, ref) => <MuiMenuItem ref={ref} {...props} disableRipple disableTouchRipple />),
)(({ theme }) => ({
    margin: `0 ${theme.spacing(0.5)}`,
    '&:hover': {
        backgroundColor: Tokens.colors.transparentBlue,
    },
    '&.Mui-selected': {
        backgroundColor: Tokens.colors.transparentBlue,
        ':hover': {
            backgroundColor: alpha(Tokens.colors.transparentBlue, 0.25),
        },
    },
    '&.Mui-focusVisible': {
        backgroundColor: Tokens.colors.transparentBlue,
    },
}));

export default MenuItem;
