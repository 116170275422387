import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { getCurrentUser, getIsAdmin, getIsHinshawAdmin, logout } from '../../redux/auth';
import { useSnackbar } from '../snackbar/context';

import Menu from './menu';
import MenuItem from '../menu-item';
import useRouteMatch from '../../utils/use-route-match';

function getAvatarName(user) {
    return user.name
        .split(' ')
        .filter(Boolean)
        .map((name) => name[0].toUpperCase())
        .join('');
}

const AccountMenu = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentUser = useSelector(getCurrentUser);
    const isAdmin = useSelector(getIsAdmin);
    const isHinshawAdmin = useSelector(getIsHinshawAdmin);
    const { openSnackbar } = useSnackbar();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickLogout = async () => {
        try {
            await dispatch(logout()).unwrap();
            navigate('/');
        } catch (error) {
            openSnackbar({
                message: 'Something went wrong! Please contact the site administrator if issues continue',
                severity: 'error',
            });
        }
    };

    const { currentRoute } = useRouteMatch(['/profile', '/carriers']);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                disableRipple
                disableFocusRipple
            >
                <Avatar
                    sx={{
                        backgroundColor: 'primary.main',
                        width: (theme) => theme.spacing(6),
                        height: (theme) => theme.spacing(6),
                    }}
                >
                    {getAvatarName(currentUser)}
                </Avatar>
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose} onClick={handleClose}>
                {isAdmin && (
                    <MenuItem component={Link} to="/carriers" selected={currentRoute === '/carriers'}>
                        Change Carrier
                    </MenuItem>
                )}
                {/* see layouts/main for explanation of auth guarding re: hinshaw admins and profile mgmt */}
                {!isHinshawAdmin && (
                    <MenuItem component={Link} to="/profile" selected={currentRoute === '/profile'}>
                        My Profile
                    </MenuItem>
                )}
                <MenuItem onClick={handleClickLogout}>Log Out</MenuItem>
            </Menu>
        </Box>
    );
};

export default AccountMenu;
