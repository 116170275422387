import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import MuiLink from '@mui/material/Link';

const Link = ({ children, to, href, sx, ...props }) => (
    <MuiLink
        href={href}
        color="primary.dark"
        // Support configuring as a router-aware link
        {...(to && { to, component: RouterLink })}
        sx={{
            '&:focus': {
                boxShadow: '0px 0px 5px rgba(0, 128, 255, 0.9)',
            },
            fontWeight: 'medium',
            ...sx,
        }}
        underline="hover"
        {...props}
    >
        {children}
    </MuiLink>
);

// eslint-disable-next-line consistent-return
const validateLinkTarget = (props, propName, componentName) => {
    if (!props.to && !props.href) {
        return new Error(`One of props 'to' or 'href' was not specified in '${componentName}'.`);
    }
};

Link.propTypes = {
    children: PropTypes.node.isRequired,
    to: validateLinkTarget,
    href: validateLinkTarget,
    sx: PropTypes.object,
};

Link.defaultProps = {
    to: '',
    href: '',
    sx: {},
};

export default Link;
