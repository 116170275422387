import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import ErrorFallback from './components/error-fallback';

import theme from './styles/material-theme';
import App from './routes';

import 'sanitize.css/sanitize.css';
import './index.css';
import ScrollToTop from './components/scroll-to-top';
import GoogleAnalytics from './components/google-analytics';
import { SnackbarProvider } from './components/snackbar/context';
import { init as sentryInit } from './utils/sentry';

import store from './redux';

if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line global-require
    const axe = require('@axe-core/react');
    axe(React, ReactDOM, 1000);
}

const container = document.getElementById('root');
const root = createRoot(container);

// initialize sentry
sentryInit();

root.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <Sentry.ErrorBoundary fallback={({ resetError }) => <ErrorFallback resetError={resetError} />}>
                    <SnackbarProvider>
                        <ScrollToTop />
                        <GoogleAnalytics />
                        <App />
                    </SnackbarProvider>
                </Sentry.ErrorBoundary>
            </BrowserRouter>
        </ThemeProvider>
    </Provider>,
);
