import { useAsyncRetry } from 'react-use';
import * as Sentry from '@sentry/react';
import { isSystemError } from '.';

export default function useAsync(fn, deps = []) {
    const state = useAsyncRetry(async () => {
        try {
            return await fn();
        } catch (err) {
            // Make sure bugs aren't silently swallowed
            if (isSystemError(err)) {
                Sentry.captureException(err);
                // Typically not using Sentry locally, so fire off to the console instead
                if (process.env.NODE_ENV === 'development') {
                    // eslint-disable-next-line no-console
                    console.error(err);
                }
            }
            throw err;
        }
    }, deps);

    return state; // { value, error, loading, retry }
}
