import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const WysiwygTypography = forwardRef(({ children, sx, ...props }, ref) => (
    <Typography
        {...props}
        // https://mui.com/system/getting-started/the-sx-prop/#passing-the-sx-prop
        sx={[
            {
                // mirror custom Link styles
                '& a': {
                    color: 'primary.dark',
                    textDecoration: 'none',
                    '&:hover': {
                        textDecoration: 'underline',
                    },
                },
            },
            ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        dangerouslySetInnerHTML={{ __html: children }}
        ref={ref}
    />
));

WysiwygTypography.propTypes = {
    children: PropTypes.node,
    sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.func]),
};

WysiwygTypography.defaultProps = {
    children: '',
    sx: [],
};

export default WysiwygTypography;
