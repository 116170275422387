import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Tokens from '../../styles/tokens';

const Block = ({ children, color, sx, to, onClick, ...props }) => (
    <Box
        sx={{
            backgroundColor: 'common.white',
            borderTop: 10,
            borderRadius: 2,
            borderTopColor: color,
            p: 4,
            ...sx,
            ...(to || onClick
                ? {
                      cursor: 'pointer',
                      display: 'block',
                      textDecoration: 'none',
                      ':hover': {
                          backgroundColor: Tokens.colors.lightestGray,
                      },
                  }
                : {}),
        }}
        {...(onClick ? { onClick } : {})}
        {...(to ? { component: Link, to } : {})}
        {...props}
    >
        {children}
    </Box>
);

Block.propTypes = {
    children: PropTypes.node,
    color: PropTypes.string,
    sx: PropTypes.object,
    to: PropTypes.string,
    onClick: PropTypes.func,
};

Block.defaultProps = {
    children: null,
    color: 'primary.main',
    sx: {},
    to: '',
    onClick: null,
};

export default Block;
