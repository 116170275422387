import { createTheme } from '@mui/material/styles';
import { common } from '@mui/material/colors';
import Tokens from './tokens';

const theme = createTheme({
    typography: {
        fontFamily: "'Inter', san-serif",
        htmlFontSize: 10, // Aligns with html rule in index.css
        fontWeightRegular: Tokens.fontWeights.regular,
        fontWeightMedium: Tokens.fontWeights.medium,
        fontWeightSemiBold: Tokens.fontWeights.semiBold,
        fontWeightBold: Tokens.fontWeights.bold,
        fontWeightExtraBold: Tokens.fontWeights.extraBold,
        h1: {
            fontSize: '3.6rem',
            fontWeight: Tokens.fontWeights.regular,
            color: common.black,
        },
        h2: {
            fontSize: '2.6rem',
            fontWeight: Tokens.fontWeights.bold,
            color: common.black,
        },
        h3: {
            fontSize: '1.8rem',
            fontWeight: Tokens.fontWeights.bold,
            color: common.black,
        },
        h4: {
            fontSize: '1.5rem',
            fontWeight: Tokens.fontWeights.bold,
            color: common.black,
        },
        h5: {
            fontSize: '1.4rem',
            fontWeight: Tokens.fontWeights.bold,
            textTransform: 'uppercase',
            color: common.black,
        },
        body1: {
            fontSize: '1.5rem',
            fontWeight: Tokens.fontWeights.regular,
            lineHeight: '2.3rem',
        },
        body2: {
            fontSize: '1.8rem',
            fontWeight: Tokens.fontWeights.semiBold,
            lineHeight: '2.5rem',
            color: Tokens.colors.gray,
        },
        caption: {
            fontSize: '1.3rem',
            lineHeight: '2.1rem',
            color: Tokens.colors.gray,
        },
        button: {
            fontSize: '1.5rem',
            fontWeight: Tokens.fontWeights.medium,
            textTransform: 'none',
            letterSpacing: '0.5px',
        },
    },
    palette: {
        primary: {
            dark: Tokens.colors.darkBlue,
            light: Tokens.colors.lightBlue,
            main: Tokens.colors.logoBlue,
            contrastText: common.white,
        },
        secondary: {
            dark: Tokens.colors.darkPurple,
            main: Tokens.colors.lightPurple,
        },
        success: {
            dark: Tokens.colors.darkGreen,
            main: Tokens.colors.mediumGreen,
            light: Tokens.colors.lightGreen,
        },
        warning: {
            main: Tokens.colors.yellow,
        },
        error: {
            main: Tokens.colors.red,
        },
        text: {
            primary: common.black,
            secondary: Tokens.colors.gray,
        },
    },
    mixins: {
        toolbar: {
            // theme.spacing(7) is the height of the AppBar
            height: '56px',
            minHeight: '56px',
        },
        footer: {
            height: '104px', // 56px toolbar + 24px padding top + 24px padding bottom (see components/footer)
        },
    },
    components: {
        // Covers text inputs (both used directly and as seen in Selects)
        MuiOutlinedInput: {
            styleOverrides: {
                root: ({ theme: thm }) => ({
                    // Textarea height should adapt to content / Mui's rows prop
                    ':not(.MuiInputBase-multiline)': {
                        height: thm.spacing(5),
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: Tokens.colors.mediumGray,
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: Tokens.colors.mediumGray,
                    },
                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.error.main,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: `2px solid ${theme.palette.primary.dark}`,
                    },
                    '&.Mui-focused .MuiInputAdornment-root': {
                        color: theme.palette.common.black,
                    },
                    '&.Mui-disabled': {
                        color: Tokens.colors.gray,
                        backgroundColor: 'transparent',
                        '& svg': {},
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: Tokens.colors.lightestGray,
                        },
                    },
                    '&.MuiOutlinedInput-input': {
                        '&::placeholder': {
                            color: Tokens.colors.grey,
                        },
                    },
                }),
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: ({ theme: thm }) => ({
                    color: thm.palette.common.black,
                    backgroundColor: thm.palette.common.white,
                    fontSize: '1.4rem',
                    fontWeight: thm.typography.fontWeightSemiBold,
                    textTransform: 'none',
                    transform: 'none',
                    position: 'relative',
                    '.MuiInputLabel-asterisk': {
                        color: thm.palette.error.main,
                    },
                    marginBottom: thm.spacing(1),
                    '&.Mui-disabled': {
                        color: Tokens.colors.gray,
                    },
                }),
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: 0,
                },
            },
        },
        MuiFormControlLabel: {
            // checkboxes, radio buttons
            styleOverrides: {
                asterisk: ({ theme: thm }) => ({
                    color: thm.palette.error.main,
                }),
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: ({ theme: thm }) => ({
                    color: thm.palette.primary.main,
                    padding: 0,
                    paddingRight: thm.spacing(1),
                }),
            },
        },
    },
});

export default theme;
